import styled from 'styled-components';
import Section from 'shared/components/Section';
import useTranslation from 'shared/hooks/useTranslation';
import { BREAKPOINT } from 'config/theme';
import InvitationStatisticsHeader from './Header';
import StatisticCard from './StatisticCard';

const BackgroundColor = styled.div`
  align-items: center;
  background-color: #f97f02;
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

const SectionForInvitationsStatistics = styled(Section)`
  color: white;
  padding-bottom: 8rem;
  padding-top: 8rem;
  margin: 0 auto;
  max-width: 160rem;
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  gap: 8rem;
  grid-template-columns: 1fr;
  justify-content: center;
  margin-top: 6rem;

  ${({ theme }) => theme.MEDIA.LG} {
    gap: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(35rem, 42rem));
    margin-top: 8rem;
  }

  @media only screen and (max-width: 1370px) and (min-width: ${BREAKPOINT.MD}) {
    align-items: flex-start;
    column-gap: 3rem;
    grid-column-gap: 8fr;
    grid-template-columns: repeat(2, minmax(35rem, 42rem));
    margin-top: 8rem;
    row-gap: 8rem;

    & > *:nth-child(3) {
      grid-column: 1/-1;
    }
  }
`;

function IncreaseSales(): JSX.Element {
  const { t } = useTranslation('homePage');

  return (
    <BackgroundColor>
      <SectionForInvitationsStatistics>
        <InvitationStatisticsHeader />
        <Grid>
          <StatisticCard numberValue={26} suffix="%">
            <b>{t('icreaseSalesSection.valueIncrease')}</b>
          </StatisticCard>
          <StatisticCard numberValue={17} suffix="%">
            <b>{t('icreaseSalesSection.increaseSales')}</b>
          </StatisticCard>
          <StatisticCard numberValue={9000} prefix="+">
            <b>{t('icreaseSalesSection.satisfiedCustomers')}</b>
          </StatisticCard>
        </Grid>
      </SectionForInvitationsStatistics>
    </BackgroundColor>
  );
}

export default IncreaseSales;
