import styled from 'styled-components';
import { BREAKPOINT } from 'config/theme';
import useTranslation from 'shared/hooks/useTranslation';
import { Heading, SubHeading } from '../Styled';

const StyledHeading = styled(Heading)`
  color: white;
  font-size: 3.6rem;
  line-height: 4.8rem;
  margin: 0 auto;

  ${({ theme }) => theme.MEDIA.MD} {
    font-size: 7.4rem;
    line-height: 7.8rem;
  }

  @media only screen and (max-width: 1370px) and (min-width: ${BREAKPOINT.MD}) {
    font-size: 6rem;
  }
`;

const StyledSubHeading = styled(SubHeading)`
  color: white;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.3rem;
  margin-top: 5rem;
`;

function InvitationStatisticsHeader(): JSX.Element {
  const { t } = useTranslation('homePage');

  return (
    <>
      <StyledHeading>{t('icreaseSalesSection.salesConversion')}</StyledHeading>
      <StyledSubHeading>{t('icreaseSalesSection.increaseSalesOfCustomers')}</StyledSubHeading>
    </>
  );
}

export default InvitationStatisticsHeader;
