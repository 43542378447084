import styled from 'styled-components';
import { ReactNode } from 'react';
import AnimatedNumber, { AnimatedNumberProps } from '../components/AnimatedNumber';

interface StatisticsCardProps extends AnimatedNumberProps {
  children?: ReactNode;
}

const Statistic = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 10.8rem;
  font-weight: 900;
  line-height: 11.5rem;

  ${({ theme }) => theme.MEDIA.LG} {
    gap: 3rem;
  }
`;

const Label = styled.div`
  @media (max-width: ${({ theme }) => theme.BREAKPOINT.MD}) {
    margin: 0 auto;
    max-width: 80%;
  }

  color: white;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 5.2rem;
  text-align: center;

  ${({ theme }) => theme.MEDIA.LG} {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 5.8rem;
  }
`;

function StatisticsCard({ numberValue, children, prefix, suffix }: StatisticsCardProps): JSX.Element {
  return (
    <Statistic>
      <AnimatedNumber numberValue={numberValue} prefix={prefix} suffix={suffix} />
      {children ? <Label>{children}</Label> : null}
    </Statistic>
  );
}

export default StatisticsCard;
